<template>
    <div class="fullwidth vh-100 h-auto" style="position: relative">

        <div class="dashboard-sidebar h-100 bg-warning" :class="{'show-side-bar': sideBarOpen}">
            <div class="text-right hidden-sm hidden-md hidden-lg">
                <x-icon size="1x" class="text-white pointer" @click="closeSideBar()"></x-icon>
            </div>
            <div class="logo-wrapper">
                <router-link to="/home">
                    <img src="/assets/remittance-assets/img/transcrypt-logo-white.png" alt="TransCrypt logo"
                         title="TransCrypt" class="pt-2">
                </router-link>
            </div>
            <div class="send-money-btn-wrapper">
                <button class="btn btn-success w-100" @click="goToTransferPage()">Send money</button>
            </div>
            <ul class="dashboard-sidebar-lists">
                <li @click="gotToHome()" class="dashboard-list-style"
                    :class="{'text-selection' : this.url === '/dashboard'}">
                    <home-icon size="1.5x" class="icon-style"></home-icon>
                    <span>Home</span>
                </li>
                <li @click="goToRecipientsPage()" class="dashboard-list-style"
                    :class="{'text-selection' : this.url === '/dashboard/recipients'}">
                    <user-icon size="1.5x" class="icon-style"></user-icon>
                    <span>Recipient</span>

                </li>
                <li @click="goToSettingsPage()" class="dashboard-list-style"
                    :class="{'text-selection' : this.url === '/dashboard/settings'}">
                    <settings-icon size="1.5x" class="icon-style"></settings-icon>
                    <span>Settings</span>

                </li>
                <li @click="goToLogoutPage()" class="dashboard-list-style">
                    <log-out-icon size="1.5x" class="icon-style"></log-out-icon>
                    <span>Logout</span>

                </li>
              <br>
<!--              <li @click="goToTranscryptTrading()" class="dashboard-list-style mt-5">-->
<!--                <div style="display:flex;flex-direction: column">-->
<!--                  <div class="text-small">Switch to</div>-->
<!--                  <small style="border:1px solid white;padding:5px;border-radius: 5px;text-align: center;margin:1px 0">TransCrypt Trading</small>-->
<!--                </div>-->
<!--              </li>-->
            </ul>
          <div class="switch-flow-wrapper">
            <div style="display: flex;flex-direction: column">
              <div class="text-success text-large fw-700">Switch to</div>
              <div class="switch-flow-inner-container pointer"
                   @click="goToTranscryptTrading()">
                <small class="text-white text-small">TransCrypt Trading</small>
                <chevron-right-icon size="1x" class="text-white"></chevron-right-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-main vh-100 h-auto w-xs-100 dashboard-bg">
            <div class="dashboard-main-nav bg-info flex-left-right p-4">
                <div class="flex-row-align-center">
                    <menu-icon size="1.5x" class="hidden-md hidden-sm hidden-lg pointer"
                               @click="openSideBar()"></menu-icon>
                </div>
                <div class="dashboard-child-nav w-100">
                    <h3 class="ml-2-xs hidden-xs" v-if="url === '/dashboard'"><strong>Home</strong></h3>
                    <h3 class="ml-2-xs" v-else-if="url === '/dashboard/recipients'"><strong>Recipients</strong></h3>
                    <h3 class="ml-2-xs" v-else-if="url === '/dashboard/settings'"><strong>Settings</strong></h3>

                    <span><strong>{{userEmail || "N/A"}}</strong></span>
                </div>
            </div>
            <div class="dashboard-main-content h-100 p-4">
                <main class="h-100 w-100">

                    <router-view></router-view>

                </main>

                <!-- Start Footer Area -->
                <!--TODO hide on mobile-->

                <!-- End Footer Area -->
            </div>

        </div>
        <footer class="dashboard-main-footer">
            <div class="footer-area-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="copyright">
                                <p>
                                    ©2021
                                    <span class="text-success"><strong>TransCrypt</strong></span> All Rights Reserved.
                                    Contact us at <a class="text-success" href="mailto:support@transcryptglobal.com?subject=Crypto%20Transfer%20Enquiry">support@transcryptglobal.com</a> if you have questions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>
<script>
    import {HomeIcon, UserIcon, SettingsIcon, LogOutIcon, MenuIcon, XIcon, ChevronRightIcon} from 'vue-feather-icons'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        components: {
            HomeIcon,
            UserIcon,
            SettingsIcon, LogOutIcon,
            MenuIcon, XIcon,
          ChevronRightIcon
        },
        data() {
            return {
                sideBarOpen: false,
                url: "",
            }
        },
        watch: {
            $route(to, from) {
                if (to.path === '/dashboard') {
                    this.url = to.path;
                } else if (to.path === '/dashboard/recipients') {
                    this.url = to.path;

                } else if (to.path === '/dashboard/settings') {
                    this.url = to.path;
                }
            }
        },
        computed: {
            ...mapGetters(["userDetailsGetter"]),
            userEmail() {
                return this.userDetailsGetter && this.userDetailsGetter.email;
            },

            classObject: function () {
                return {
                    showSideBar: this.sideBarOpen
                }
            }
        },
        mounted() {
            let url = new URL(window.location);

            this.url = url.pathname;
        },
        methods: {
          ...mapActions(["submitTransferType"]),
            goToTransferPage() {
                this.$router.push('/transfer/remittance-form')
            },
            gotToHome() {
                this.$router.push('/dashboard').catch(() => {
                })
                this.sideBarOpen = false;
            },
            goToRecipientsPage() {
                this.$router.push('/dashboard/recipients').catch(() => {
                })
                this.sideBarOpen = false;
            },
            goToSettingsPage() {
                this.$router.push('/dashboard/settings').catch(() => {
                })
                this.sideBarOpen = false;
            },
            goToLogoutPage() {
                this.$router.push('/account/logout')
                this.sideBarOpen = false;
            },
            openSideBar() {
                this.sideBarOpen = true;
                if (this.debugConsole) console.log("This side bar open", this.sideBarOpen);
            },
            closeSideBar() {
                this.sideBarOpen = false;
                if (this.debugConsole) console.log("This side bar close", this.sideBarOpen);
            },
          goToTranscryptTrading(){
            this.$router.push('/trading/main')

            this.submitTransferType('trading');
          }


        }

    }
</script>
<style scoped>
    img {
        margin-bottom: 20px;
    }

    .dashboard-sidebar-lists {
        display: block;
        text-align: left;
        margin: 30px 15px 0px 15px;
    }

    .dashboard-list-style {
        display: flex;
        margin: 20px 0;
        color: #ffffff;
        font-weight: 800;
        cursor: pointer;
    }

    .icon-style {
        margin-right: 5px;
    }

    .dashboard-bg {
        background-color: #F2F5F7;
    }


</style>